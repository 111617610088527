import React from 'react';
import * as Survey from 'survey-react';
import LoadingButton from 'components/LoadingButton/LoadingButton';
import { Spinner } from 'react-bootstrap';
import ReactDOM from 'react-dom';

const WindowEventButtonWidget = {
  name: 'windoweventbuttonwidget',
  title: 'Window event button',
  templateHtml: '<div></div>',

  widgetIsLoaded: () => {
    return true; // We do not have external scripts
  },

  init: () => {
    // Register a new type using the empty question as the base.
    Survey.Serializer.addClass(
      'windoweventbuttonwidget',
      [
        { name: 'visible', visible: false },
        { name: 'readOnly', visible: false },
        { name: 'isRequired', visible: false },
        { name: 'displayName', visible: false },
        { name: 'requiredIf', visible: false },
        { name: 'validators', visible: false },
        { name: 'requiredErrorText', visible: false },
        { name: 'title', visible: false },
        { name: 'titleLocation', visible: false, default: 'hidden' },
        { name: 'width', visible: false },
        { name: 'minWidth', visible: false },
        { name: 'maxWidth', visible: false },
        { name: 'valueName', visible: false },
        { name: 'defaultValue', visible: false },
        { name: 'correctAnswer', visible: false },
        { name: 'useDisplayValuesInTitle', visible: false },
        {
          name: 'buttonText',
          displayName: 'Button text',
          default: 'Click me',
          category: 'general',
        },
        {
          name: 'eventName',
          displayName: 'Event name',
          category: 'general',
          default: 'survey.btn.click',
        },
      ],
      null,
      'empty',
    );
  },

  isFit: (question) => {
    return (
      question.getType() === 'windoweventbutton' ||
      question.getType() === 'signbutton' ||
      question.getType() === 'exportpdfbutton'
    ); // TODO: prevent this downward dependency
  },

  afterRender: (question, htmlElement) => {
    const clickHandler = () => {
      const event = new Event(question.eventName, { bubbles: true });

      event.survey = question.survey;
      window.dispatchEvent(event);

      // Event handler that want to block btn can assign a promise to the event
      return event.promise;
    };

    ReactDOM.render(
      <LoadingButton
        onClick={clickHandler}
        text={question.buttonText}
        loadingText={
          <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">loading ...</span>
          </Spinner>
        }
      />,
      htmlElement,
    );
  },
};

export default WindowEventButtonWidget;
