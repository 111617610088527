import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const LoadingButton = ({ onClick, disabled, loadingText, text, ...rest }) => {
  const [isLoading, setLoading] = useState(false);

  const handleClick = () => {
    const onClickReturn = onClick();

    if (Promise.resolve(onClickReturn) === onClickReturn) {
      setLoading(true);
      onClickReturn.then(() => setLoading(false)).catch(() => setLoading(false));
    }
  };

  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      onClick={!isLoading ? handleClick : null}
    >
      {isLoading ? loadingText : text}
    </Button>
  );
};

export default LoadingButton;
