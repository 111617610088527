import { IReduxAction } from 'types/IReduxAction';
import { SET_ACT, RESET_ACT } from '_redux/types';
import { IActResponse, IActsResponse } from './types';

export interface IActState {
  actListPage?: number;
  actList?: IActsResponse['hydra:member'];
  actListCount?: IActsResponse['hydra:totalItems'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  act?: IActResponse;
}
const initialState: IActState = { actList: [] };

export default (state = initialState, action: IReduxAction): IActState => {
  switch (action.type) {
    case SET_ACT:
      return { ...state, ...action.payload };
    case RESET_ACT:
      return initialState;

    default:
      return state;
  }
};
