export const fileUploaderOptions = {
  idDocument: [
    [
      {
        name: 'customLabel',
        displayName: 'custom Action Label',
        category: 'Buttonconfig',
        type: 'text',
      },
      {
        name: 'customEventName',
        displayName: 'custom Action Event Name',
        category: 'Buttonconfig',
        type: 'text',
      },
    ],
  ],
};
