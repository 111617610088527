import api from 'api';
import { errorHandler } from 'helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import { IActResponse, IActsResponse } from '_redux/reducers/act/types';
import { SET_ACT } from '_redux/types';
import { startLoading, stopLoading } from '../loading';
export const getActs = (
  token: string,
  displayFields: string[],
  page = 0,
): IThunkAction => async (dispatch) => {
  let link = '/acts?';

  displayFields.forEach((o) => {
    link = `${link}dataFields[]=[0][${o}]&`;
  });
  link = `${link}&page=${page + 1}`;

  dispatch(startLoading());
  try {
    const actListResponse = await api.get<IActsResponse>(link);

    dispatch({
      type: SET_ACT,
      payload: {
        actListPage: page,
        actList: actListResponse.data['hydra:member'],
        actListCount: actListResponse.data['hydra:totalItems'],
      },
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getOneAct = (token: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const actListResponse = await api.get<IActResponse>(`/acts/${token}`);

    dispatch({
      type: SET_ACT,
      payload: {
        act: actListResponse.data,
      },
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
