import { notification } from 'antd';

export const successHandler = ({
  message,
  description,
}: {
  message: string;
  description?: string;
}) => {
  notification.success({
    message,
    description,
    placement: 'topRight',
    duration: 1.5,
  });
};
