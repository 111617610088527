import { IReduxAction } from 'types/IReduxAction';
import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_LOGGING_IN } from '_redux/types';

export interface IUser {
  data?: { access_token: string };
  isLoading: boolean;
  redirectUri?: string;
}

const initialState: IUser = {
  isLoading: false,
};

export default (state = initialState, { type, payload }: IReduxAction): IUser => {
  switch (type) {
    case USER_LOGGING_IN:
      return {
        ...initialState,
        isLoading: true,
        redirectUri: payload.redirectUri,
      };
    case USER_LOGGED_IN:
      return { data: payload, isLoading: false };
    case USER_LOGGED_OUT:
      return initialState;
    default:
      return state;
  }
};
