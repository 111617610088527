import { AxiosError } from 'axios';
import { notification } from 'antd';
export const isAxiosError = (error: unknown): error is AxiosError =>
  (error as AxiosError).isAxiosError === true;

export const errorHandler = (error: AxiosError | Error) => {
  if (isAxiosError(error) && error.response) {
    notification.error({
      message: error.response.data['hydra:title'] || error.response.data.message,
      description: error.response.data['hydra:description'],
      placement: 'topRight',
      duration: 1.5,
    });
  } else {
    notification.error({
      message: error.message,
      placement: 'topRight',
      duration: 1.5,
    });
  }
};
