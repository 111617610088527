export const exportPdfOptions = {
  exportpdfbutton: [
    {
      name: 'visible',
      visible: false,
    },
    {
      name: 'readOnly',
      visible: false,
    },
    {
      name: 'isRequired',
      visible: false,
    },
    {
      name: 'displayName',
      visible: false,
    },
    {
      name: 'requiredIf',
      visible: false,
    },
    {
      name: 'validators',
      visible: false,
    },
    {
      name: 'requiredErrorText',
      visible: false,
    },
    {
      name: 'title',
      visible: false,
    },
    {
      name: 'width',
      visible: false,
    },
    {
      name: 'minWidth',
      visible: false,
    },
    {
      name: 'maxWidth',
      visible: false,
    },
    {
      name: 'valueName',
      visible: false,
    },
    {
      name: 'defaultValue',
      visible: false,
    },
    {
      name: 'correctAnswer',
      visible: false,
    },
    {
      name: 'useDisplayValuesInTitle',
      visible: false,
    },
    [
      {
        name: 'buttonText',
        displayName: 'Button text',
        default: 'Exporter PDF',
        category: 'general',
      },
    ],
    {
      name: 'titleLocation',
      visible: false,
      default: 'hidden,',
    },
  ],
};
