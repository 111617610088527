import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './StartPage.css';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const StartPage = (props) => {
  const step = useSelector((state) => state.step.step);
  const [startPageText, setStartPageText] = useState('');

  useEffect(() => {
    // TODO: remove this and bind directly to step after null object pattern
    if (step) {
      setStartPageText(step.homePage);
    }
  }, [step]);

  return (
    <div className="container">
      <div className="text" dangerouslySetInnerHTML={{ __html: startPageText }} />
      <Link to={`/${props.match.params.token}/form`} className="text-white">
        <Button
          className="float-right btn"
          style={{
            // backgroundColor: `${startPageData.color}`,
            border: 'none',
          }}
        >
          Start
        </Button>
      </Link>
    </div>
  );
};

export default StartPage;
