import { useDispatch, useSelector } from 'react-redux';

import * as queryString from 'query-string';
import { USER_LOGGED_IN } from '_redux/types';
import React from 'react';
import { useHistory } from 'react-router-dom';

const OAuth2Redirect = () => {
  const dispatch = useDispatch();
  const parsedHash = queryString.parse(window.location.hash);
  const redirectUri = useSelector((state) => state.user.redirectUri);

  dispatch({ type: USER_LOGGED_IN, payload: { access_token: parsedHash.access_token } });
  const history = useHistory();

  if (!!redirectUri && redirectUri.length > 0) {
    history.push(redirectUri);
  }

  return <></>;
};

export default OAuth2Redirect;
