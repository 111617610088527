import React from "react";

const ErrorPage = ({ errorCode, errorMessage, errorDescription }) => {
  return (
    <div className="container">
      <div className="d-flex justify-content-center align-items-center">
        <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">
          {errorCode}
        </h1>
        <div className="inline-block align-middle">
          <h2 className="lead">{errorMessage}</h2>
          <p className="font-weight-light">{errorDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
