import api from 'api';
import { IThunkAction } from 'types/IThunkAction';
import download from 'downloadjs';
import { errorHandler } from 'helpers/errorHandler';
import { startLoading, stopLoading } from '../loading';

export const exportPdfHandler = (actToken: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`/acts/${actToken}`, {
      headers: { accept: 'application/pdf' },
      responseType: 'blob',
      transformResponse: (r) => r,
    });

    download(response.data, `act-${actToken}.pdf`, 'application/pdf');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
