import { IReduxAction } from 'types/IReduxAction';
import { RESET_STEP, SET_STEP } from '_redux/types';
import { ISurvey } from './types';

export interface IStepState {
  step: ISurvey | null;
}

const initialState: IStepState = { step: null }; // TODO: Use null object pattern to remove if (step) { in components}

export default (state = initialState, action: IReduxAction): IStepState => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.payload };
    case RESET_STEP:
      return initialState;

    default:
      return state;
  }
};
