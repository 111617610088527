import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Integrations } from '@sentry/tracing';
import { Slide, ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import store, { persistor } from './_redux/store';
import App from './containers/App/App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
    <ToastContainer
      transition={Slide}
      closeButton={false}
      draggable={false}
      pauseOnHover
      closeOnClick
      hideProgressBar
    />
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
