import $ from 'jquery';
import { addSurveyProperties } from 'helpers/addSurveyProperties';
import { fileUploaderOptions } from './fileUploaderOptions';

const fileUploaderCompnent = {
  name: 'idDocument',
  iconName: 'icon-file',
  title: 'idDocument',
  questionJSON: {
    type: 'file',
    maxSize: 0,
  },
  onInit: () => {
    // Add orderItems properties. It is an array of ordertableitem elements
    addSurveyProperties(fileUploaderOptions);
  },

  /**
   *
   * @param {} question
   */
  onCreated: () => {
    /**
     * You can use this function to setup some properties of
     * your content question/elements based on properties of the component/root question.
     * SurveyJS calls this function after the question is loaded from survey json.
     */
  },
  /**
   *
   * @param {*} question
   */
  onLoaded: () => {
    // Create rows and default values on first loading
  },
  // Calls on property changed in component/root question
  onPropertyChanged: (question, propertyName, newValue) => {
    if (propertyName === 'value' && newValue.length > 0) {
      const event = new Event('survey.id-document.validate', { bubbles: true });

      event.questionName = question.name;
      window.dispatchEvent(event);
    }
  },
  /**
   *
   * @param {*} question
   * @param {*} options
   */
  onItemValuePropertyChanged: () => {
    // console.log('onItemValuePropertyChanged');
  },
  /**
   *
   * @param {*} question
   * @param {*} element
   * @param {*} htmlElement
   */
  onAfterRenderContentElement: () => {
    // For example, add new elements to htmlElement based on some logic
    // question is the component question and element is a content element (question or panel)
  },
  /**
   *
   * @param {*} question
   * @param {*} htmlElement
   */
  onAfterRender: (question) => {
    const style = `<style> #${question.id} .sv_q_file_remove_button 
    {display: none !important;} </style>`;

    $(style).appendTo('head');
  },
  /**
   *
   * @param {*} question
   */
  callCustomFunction: () => {
    // console.log('I am a simple fumction');
  },
};

export default fileUploaderCompnent;
