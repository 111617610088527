/* eslint-disable @typescript-eslint/no-explicit-any */
import api from 'api';
import { errorHandler } from 'helpers/errorHandler';
import { SurveyModel } from 'survey-react';
import { IThunkAction } from 'types/IThunkAction';
import { IActResponse } from '_redux/reducers/act/types';
import { SET_ACT } from '_redux/types';
import { startLoading, stopLoading } from '../loading';

export const surveyValidateQuestion = (step: string, actToken: string): IThunkAction => (
  dispatch,
) => async (surveydata: SurveyModel, options: any) => {
  const formdata = {
    data: surveydata.data,
    step,
  };

  dispatch(startLoading());
  if (actToken) {
    try {
      const actListResponse = await api.put(`/acts/${actToken}`, formdata);

      dispatch({
        type: SET_ACT,
        payload: {
          act: actListResponse.data,
        },
      });
      options.complete();
    } catch (error) {
      if (error.response.data?.violations) {
        error.response.data.violations.map((violation: any) => {
          options.errors[violation.propertyPath.substr(1, violation.propertyPath.length - 2)] =
            violation.message;

          return violation;
        });
      } else {
        errorHandler(new Error('Une erreur est survenue lors de la sauvegarde'));
      }
    }
  } else {
    try {
      const actListResponse = await api.post<IActResponse>(`/acts`, formdata);

      if (actListResponse.data) {
        dispatch({
          type: SET_ACT,
          payload: {
            act: actListResponse.data,
          },
        });
        options.complete();
      }
    } catch (error) {
      if (error.response.data?.violations) {
        error.response.data.violations.map((violation: any) => {
          options.errors[violation.propertyPath.substr(1, violation.propertyPath.length - 2)] =
            violation.message;

          return violation;
        });
      } else {
        errorHandler(new Error('Une erreur est survenue lors de la sauvegarde'));
      }
    }
  }
  dispatch(stopLoading());
};
