import React from 'react';
import { useSelector } from 'react-redux';
import 'pages/Home/Home.css';

const EndPage = () => {
  const step = useSelector((state) => state.step.step);

  return (
    <div className="container">
      <div className="end-text" dangerouslySetInnerHTML={{ __html: step?.endPage }} />
    </div>
  );
};

export default EndPage;
