import api from 'api';
import { errorHandler } from 'helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import { IClient } from '_redux/reducers/customer/types';
import { ISurvey } from '_redux/reducers/step/types';
import { RESET_STEP, SET_CUSTOMER, SET_STEP } from '_redux/types';
import { startLoading, stopLoading } from '../loading';

export const getStep = (token: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get<ISurvey>(`/steps/${token}`);

    const clientId = response.data.client.replace('/api', '');

    const userResponse = await api.get<IClient>(`${clientId}`);

    dispatch({ type: SET_CUSTOMER, payload: userResponse.data });
    dispatch({ type: SET_STEP, payload: response.data });
  } catch (error) {
    dispatch({ type: RESET_STEP, payload: null });
    errorHandler(error);
  }

  dispatch(stopLoading());
};
