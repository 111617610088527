import { IReduxAction } from 'types/IReduxAction';
import { SET_CUSTOMER, RESET_CUSTOMER } from '_redux/types';
import { IClient } from './types';

export interface ICustomerState {
  client?: IClient;
}

const initialState: ICustomerState = {};

export default (state = initialState, action: IReduxAction): ICustomerState => {
  switch (action.type) {
    case SET_CUSTOMER:
      return { ...state, client: action.payload };
    case RESET_CUSTOMER:
      return {};

    default:
      return state;
  }
};
