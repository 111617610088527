import { addSurveyProperties } from 'helpers/addSurveyProperties';
import { exportPdfOptions } from './exportPdfOptions';

const ExportPdfButtonComponent = {
  name: 'exportpdfbutton',
  title: 'Export PDF button',
  questionJSON: {
    type: 'windoweventbuttonwidget',
  },

  onInit: () => {
    // TODO: subclassing here kills all onEvent calls on this component,
    // need a way to take / override parent props
    // SurveyKo.Serializer.addClass('exportpdfbutton', [], null, 'windoweventbutton');
    addSurveyProperties(exportPdfOptions);
  },

  onLoaded: (question) => {
    // TODO: why can't this be passed in questionJSON ?
    question.eventName = 'survey.export.pdf';
  },
};

export default ExportPdfButtonComponent;
