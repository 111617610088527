import { addSurveyProperties } from 'helpers/addSurveyProperties';
import { signButtonOptions } from './signButtonOptions';

const SignButtonComponent = {
  name: 'signbutton',
  title: 'Sign button',
  questionJSON: {
    type: 'windoweventbuttonwidget',
  },
  onInit: () => {
    // TODO: subclassing here kills all onEvent calls on this component, need a way to take
    // or override parent props
    // Survey.Serializer.addClass('signbutton', [], null, 'windoweventbutton');
    addSurveyProperties(signButtonOptions);
  },

  onLoaded: (question) => {
    // TODO: why can't this be passed in questionJSON ?
    question.eventName = 'survey.sign';
  },
};

export default SignButtonComponent;
