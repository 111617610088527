import React, { Fragment } from 'react';
import './App.css';
import Routes from 'containers/routes';
import 'bootstrap/dist/css/bootstrap.css';

const App = () => {
  return (
    <>
      <Routes />
    </>
  );
};

export default App;
