/* eslint-disable @typescript-eslint/no-explicit-any */
import api from 'api';
import { errorHandler } from 'helpers/errorHandler';
import { successHandler } from 'helpers/successHandler';
import { SurveyModel } from 'survey-react';
import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from '../loading';

export const validateIdDocumentHandler = (
  survey: SurveyModel,
  step: string,
  actToken: string,
): IThunkAction => (dispatch) => async ({ questionName }: { questionName: string }) => {
  const question = survey.getQuestionByName(questionName);
  const payload = {
    data: [survey.data],
    step,
  };

  dispatch(startLoading());
  try {
    await api.put(`/acts/${actToken}`, payload);

    successHandler({ message: 'Document(s) valide(s)' });
  } catch (error) {
    if (error.response) {
      if (error.response.data.violations) {
        error.response.data?.violations.map((violation: any) => {
          const violationQuestionName = violation.propertyPath.substr(
            1,
            violation.propertyPath.length - 2,
          );

          if (violationQuestionName === questionName) {
            question.addError(violation.message);
          }

          return violation;
        });

        if (question.currentErrorCount === 0) {
          successHandler({ message: 'Document(s) valide(s)' });
        }
      } else {
        errorHandler(new Error('Une erreur est survenue lors de la validation'));
      }
    }
  }

  dispatch(stopLoading());
};
