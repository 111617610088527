import React from 'react';
import { useSelector } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import './NavBar.css';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const client = useSelector(({ customerReducer }) => customerReducer.client);
  const step = useSelector(({ step: { step } }) => step);

  return (
    <Navbar bg="white" variant="light" className="shadow">
      <Navbar.Brand className="ml-3">
        <Link to={step ? `/${step.token}` : '/'}>
          <img src={client ? client.logo : '/kiora-logo.png'} alt="Logo" height={50} />
        </Link>
      </Navbar.Brand>
    </Navbar>
  );
};

export default NavBar;
