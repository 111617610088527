import { generatePath } from 'react-router';
import { useDispatch } from 'react-redux';
import * as queryString from 'query-string';
import { USER_LOGGING_IN } from '_redux/types';
import React from 'react';

const Login = () => {
  const dispatch = useDispatch();
  const parsedQueryString = queryString.parse(window.location.search);

  dispatch({ type: USER_LOGGING_IN, payload: { redirectUri: parsedQueryString.redirect } });

  const queryStringMap = {
    response_type: 'token',
    client_id: process.env.REACT_APP_OAUTH2_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.host}${generatePath(
      '/oauth2-redirect',
    )}`,
  };

  window.location = `${process.env.REACT_APP_OAUTH2_AUTHORIZATION_URL}?${queryString.stringify(
    queryStringMap,
  )}`;

  return <></>;
};

export default Login;
