/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import './Demat.css';
import FileUploaderCompnent from 'components/surveyjs/component/FileUploader';
import Helmet from 'react-helmet';
import WindowEventButtonWidget from 'components/surveyjs/widget/WindowEventButtonWidget';
import SignButtonComponent from 'components/surveyjs/component/SignButtonComponent';
import ExportPdfButtonComponent from 'components/surveyjs/component/ExportPdfButtonComponent';
import { Fab, makeStyles } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { addSurveyWidgetsAndComps } from 'helpers/addSurveyWidgetsAndComps';
import { addSurveyProperties } from 'helpers/addSurveyProperties';
import { SurveyJsProperties } from 'constants/SurveyJsOptions';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { getOneAct } from '_redux/actions/acts';
import { surveyValidateQuestion } from '_redux/actions/acts/createOrUpdate';
import { validateIdDocumentHandler } from '_redux/actions/acts/validateIdDocumentHandler';
import SignDocument from 'containers/SignDocument';
import { exportPdfHandler } from '_redux/actions/acts/exportPdfHandler';
import { RootState } from '_redux/reducers';
Survey.StylesManager.applyTheme('default');

addSurveyWidgetsAndComps({
  widgets: [{ type: 'customtype', widget: WindowEventButtonWidget }],
  componentCollectors: [FileUploaderCompnent, SignButtonComponent, ExportPdfButtonComponent],
});
// Signature config
addSurveyProperties(SurveyJsProperties);

const Demat = () => {
  const dispatch = useDispatch();
  const stepObject = useSelector(({ step: { step } }: RootState) => step);
  const act = useSelector(({ act }: RootState) => act.act);
  const { client } = useSelector(({ customerReducer }: RootState) => customerReducer);
  const match = useRouteMatch<{ act?: string; token: string }>();
  const [survey, setSurvey] = useState(new Survey.Model());
  const history = useHistory();
  const actToken: string = match.params.act || act?.token || '';
  const header = stepObject?.metadata;
  const step = stepObject?.['@id'];

  useEffect(() => {
    const step = stepObject;

    if ((actToken ?? '').length > 0 && !act) {
      dispatch(getOneAct(actToken));
    }

    if (step) {
      setSurvey(new Survey.Model(header));
    }
  }, [stepObject, dispatch, header]);

  useEffect(() => {
    if (act) {
      survey.data = { ...survey.data, ...act.data };
    }
  }, [survey, act]);
  // Separate hook for separate deps

  useEffect(() => {
    const validateIdDocumentHandlerEvent = () =>
      dispatch(validateIdDocumentHandler(survey, step || '', actToken));
    const exportPdfHandlerEvent = () => dispatch(exportPdfHandler(actToken));

    window.addEventListener('survey.id-document.validate', validateIdDocumentHandlerEvent);
    window.addEventListener('survey.export.pdf', exportPdfHandlerEvent);
    survey.onServerValidateQuestions.clear();
    survey.onServerValidateQuestions.add(
      dispatch(surveyValidateQuestion(step || '', actToken)),
    );

    return () => {
      window.removeEventListener(
        'survey.id-document.validate',
        validateIdDocumentHandlerEvent,
      );
      window.removeEventListener('survey.export.pdf', exportPdfHandlerEvent);
    };
  }, [actToken, step, survey, dispatch]);

  survey.questionErrorLocation = 'bottom';

  survey.onComplete.add(() => {
    history.push(`${match.url.replace('/form', '')}/complete`);
  });

  const useStyles = makeStyles((theme) => ({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(9),
      right: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  if (!stepObject) return <></>;

  return (
    <div className="container">
      {client?.actListEnabled ? (
        <Link to={`/${match.params.token}`}>
          <Fab color="primary" aria-label="add" className={classes.fab}>
            <ListIcon />
          </Fab>
        </Link>
      ) : (
        ''
      )}
      <div>
        {header && (
          <div>
            <div className="header-img">
              <img height="150px" src={header.logo?.fr || client?.logo} alt="Logo" />
            </div>
            <p className="header-title">{header.title?.fr || ''}</p>
            <h5 className="header-description">{header.description?.fr || ''}</h5>
          </div>
        )}
      </div>
      <Survey.Survey model={survey} />
      <SignDocument actToken={actToken} survey={survey} act={act} />
      <Helmet>
        <script src={process.env.REACT_APP_UNIVERSIGN_EMBED_URL} type="text/javascript" />
      </Helmet>
    </div>
  );
};

export default Demat;
