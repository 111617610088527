import api from 'api';
import React from 'react';
import { errorHandler } from 'helpers/errorHandler';
import { successHandler } from 'helpers/successHandler';
import get from 'lodash/get';
import { SurveyModel } from 'survey-react';
import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from '../loading';

export const signDocuments = (
  survey: SurveyModel,
  actToken: string,
  iFrameContainer: React.RefObject<HTMLDivElement>,
  iFrame: React.RefObject<HTMLIFrameElement>,
  setSigned: (value: boolean) => void,
  isSigned: boolean,
): IThunkAction => async (dispatch, getState) => {
  const { client } = getState().customerReducer;

  if (isSigned) {
    errorHandler(new Error('Form already signed'));
  }
  dispatch(startLoading());
  try {
    const signedDocument = get(survey.data, survey.signature_signedDocument, '');

    if (!iFrame.current || !iFrameContainer.current) return;

    if (!signedDocument?.[0]?.content && get(survey.data, survey.signature_mode, '') !== 'web')
      throw new Error('please upload your document');
    const response = await api.post(`/act_signatures`, {
      act: `/api/acts/${actToken}`,
      mode: get(survey.data, survey.signature_mode, ''),
      persons: [
        {
          firstName: get(survey.data, survey.signature_firstname_1, ''),
          lastName: get(survey.data, survey.signature_lastname_1, ''),
          phone: get(survey.data, survey.signature_phone_1, ''),
          mail: get(survey.data, survey.signature_email_1, ''),
        },
        {
          firstName: get(survey.data, survey.signature_firstname_2, ''),
          lastName: get(survey.data, survey.signature_lastname_2, ''),
          phone: get(survey.data, survey.signature_phone_2, ''),
          mail: get(survey.data, survey.signature_email_2, ''),
        },
      ],
      signedDocument: signedDocument?.[0]?.content,
    });

    if (get(survey.data, survey.signature_mode, '') === 'web')
      switch (client?.eSignProvider) {
        case 'universign':
          successHandler({ message: 'Ouverture du document à signer' });
          iFrameContainer.current.style.display = 'block';

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.universignSigInit(
            'kiora-form-sign-iframe-container',
            response.data.token,
            { redirectionMode: 'IN' },
            `${process.env.REACT_APP_UNIVERSIGN_SIGN_URL}`
              ? `${process.env.REACT_APP_UNIVERSIGN_SIGN_URL}`
              : null,
          );

          break;
        case 'docusign':
          iFrameContainer.current.style.display = 'block';

          iFrame.current.src = get(response, 'data.metadata.viewUrl');
          break;
        default:
          successHandler({ message: 'Signature envoyée' });
          break;
      }
    setSigned(true);
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
