/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import StepTokenGuardedRoute from 'components/StepTokenGuardedRoute/StepTokenGuardedRoute';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Demat from 'pages/Demat/Demat';
import EndPage from 'pages/EndPage/Endpage';
import OAuth2Redirect from 'pages/OAuth2Redirect';
import StartPage from 'pages/StartPage';
import { useSelector } from 'react-redux';
import { RootState } from '_redux/reducers';
import { resetAuthToken } from 'api';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the
  // landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps: any) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/login',
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: (state: RootState) => !state.user.data,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

const Routes = () => {
  const token = useSelector(({ user }: RootState) => user.data?.access_token);

  resetAuthToken(token);

  return (
    <div>
      <NavBar />
      <Switch>
        <Route path="/login" component={userIsNotAuthenticated(Login)} />
        <Route path="/oauth2-redirect" component={OAuth2Redirect} />
        <StepTokenGuardedRoute exact path="/:token?" component={Home} />
        <StepTokenGuardedRoute exact path="/:token/start" component={StartPage} />
        <StepTokenGuardedRoute path="/:token/form" component={Demat} />
        <StepTokenGuardedRoute path="/:token/complete" component={EndPage} />
        <StepTokenGuardedRoute path="/:token/:act/complete" component={EndPage} />
        <StepTokenGuardedRoute path="/:token/:act/form" component={Demat} />
        <StepTokenGuardedRoute component={Home} />
      </Switch>
      <Footer />
    </div>
  );
};

export default Routes;
