import * as Survey from 'survey-react';

export const addSurveyWidgetsAndComps = ({ widgets, componentCollectors }) => {
  widgets.forEach((widget) => {
    Survey.CustomWidgetCollection.Instance.add(widget.widget, widget.type);
  });
  componentCollectors.forEach((component) => {
    Survey.ComponentCollection.Instance.add(component);
  });
};
