import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Home.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import get from 'lodash/get';
import AddIcon from '@material-ui/icons/Add';
import { MdEdit } from 'react-icons/md';
import { Fab, makeStyles, TableFooter, TablePagination } from '@material-ui/core';
import { SET_ACT } from '_redux/types';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';
import { getActs } from '_redux/actions/acts';

const Home = () => {
  const dispatch = useDispatch();
  const { client } = useSelector(({ customerReducer }) => customerReducer);
  const useStyles = makeStyles((theme) => ({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(9),
      right: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const match = useRouteMatch();

  const isActListLoading = useSelector(({ loadingReducer }) => loadingReducer.loading);
  const { actList, actListPage, actListCount } = useSelector(({ act }) => act);

  const actColsMapping = {};
  const { token } = match.params;

  client.displayDataFields.forEach((o) => {
    actColsMapping[o] = o;
  });

  useEffect(() => {
    if (client?.actListEnabled) {
      dispatch(getActs(token, client.displayDataFields));
    }
  }, [client?.actListEnabled, client.displayDataFields, dispatch, token]);

  if (!client?.actListEnabled) return <Redirect to={`/${token}/start`} />;

  const newActClickHandler = () => {
    dispatch({ type: SET_ACT, payload: { act: null } });
  };

  const tableChangePageHandler = (e, page) => {
    dispatch(getActs(token, client.displayDataFields, page));
  };

  return (
    <div className="container">
      <Link to={`/${token}/start`}>
        <Fab
          color="primary"
          aria-label="add"
          onClick={newActClickHandler}
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      </Link>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {Object.keys(actColsMapping).map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isActListLoading && !actList?.length > 0
              ? [...Array(actList.length > 0 ? actList.length : 5).keys()].map((l, index) => (
                  <TableRow key={`${index + 1}`}>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    {Object.keys(actColsMapping).map((o) => (
                      <TableCell key={o}>
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : actList.map((act) => (
                  <TableRow key={act.token}>
                    <TableCell>
                      <Link onClick={newActClickHandler} to={`/${token}/${act.token}/form`}>
                        <MdEdit />
                      </Link>
                    </TableCell>
                    {Object.keys(actColsMapping).map((key) => (
                      <TableCell key={key}>{get(act, `data[0]['${key}']`, '')}</TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={actListCount || 0}
                rowsPerPage={30}
                page={actListPage || 0}
                onChangePage={tableChangePageHandler}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Home;
