import { combineReducers } from 'redux';
import act from './act';
import step from './step';
import user from './user';
import loadingReducer from './loading';
import customerReducer from './customer';

const rootReducer = combineReducers({
  step,
  user,
  act,
  loadingReducer,
  customerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
