import * as Survey from 'survey-react';

export const addSurveyProperties = (options) => {
  Object.keys(options).forEach((path) => {
    if (Array.isArray(options[path]))
      options[path].forEach((option) => {
        Survey.Serializer.addProperties(path, option);
      });
    else Survey.Serializer.addProperties(path, options[path]);
  });
};
