import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import Error from 'containers/Error/Error';
import { getStep } from '_redux/actions/steps';
import { stopLoading } from '_redux/actions/loading';
const ComponentWrapper = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { token } = match.params;
  const isLoading = useSelector(({ loadingReducer }) => loadingReducer.loading);
  const step = useSelector(({ step: { step } }) => step);

  useEffect(() => {
    if (token && token.length > 0) {
      dispatch(getStep(token));
    } else {
      dispatch(stopLoading());
    }
  }, [token, dispatch]);

  return (
    <>
      <div className={`${isLoading && !step ? '' : 'd-none'}`}>
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className={`${!(isLoading && !step) ? '' : 'd-none'}`}>
        {step ? (
          <Component {...rest} />
        ) : (
          <Error
            errorCode="403"
            errorMessage="Access denied"
            errorDescription="You do not have the permissions to access this page"
          />
        )}
      </div>
    </>
  );
};

export default ComponentWrapper;
