import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
      <div className="container-fluid border-top justify-content-center footer-position pt-3">
        <p className="text-center" align="center">Copyright Kiora 2020</p>
      </div>
    )
}

export default Footer