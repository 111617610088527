import axios from 'axios';
import * as tokenProvider from 'axios-token-interceptor';

export const BASE_URL = process.env.REACT_APP_API_BASE;

const api = axios.create({
  baseURL: BASE_URL,
});

// TODO: make the token dynamic
export const resetAuthToken = async (token) => {
  if (token) {
    api.interceptors.request.use(
      tokenProvider({
        token: process.env.REACT_APP_API_OAUTH2_ACCESS_TOKEN,
      }),
    );
  } else {
    api.defaults.headers.Authorization = null;
  }
};
export default api;
