import { IThunkAction } from 'types/IThunkAction';
import { LOADING_FINISHED, LOADING_START } from '_redux/types/loading';

export const startLoading = (): IThunkAction => (dispatch) => {
  dispatch({ type: LOADING_START });
};

export const stopLoading = (): IThunkAction => (dispatch) => {
  dispatch({ type: LOADING_FINISHED });
};
