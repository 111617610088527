export const SurveyJsProperties = {
  question: [
    [
      {
        name: 'typeAlias',
        category: 'general',
        default: '',
      },
    ],
  ],
  survey: [
    // Step documents config
    [
      {
        name: 'step_documents',
        displayName: 'Documents',
        category: 'Step documents',
        type: 'stepdocuments',
      },
      {
        name: 'step_documents_rules',
        displayName: 'Documents rules',
        category: 'Step documents',
        type: 'itemvalues',
      },
    ],
    // Signature config
    [
      {
        name: 'signature_mode',
        displayName: 'Mode',
        category: 'Signature',
        type: 'checkbox',
      },
      {
        name: 'signature_signedDocument',
        displayName: 'Document',
        category: 'Signature',
        type: 'checkbox',
      },
    ],
    [
      {
        name: 'signature_firstname_1',
        displayName: 'Firstname',
        category: 'First signatory',
        type: 'checkbox',
      },
      {
        name: 'signature_lastname_1',
        displayName: 'Lastname',
        category: 'First signatory',
        type: 'checkbox',
      },
      {
        name: 'signature_phone_1',
        displayName: 'Tél.',
        category: 'First signatory',
        type: 'checkbox',
      },
      {
        name: 'signature_email_1',
        displayName: 'Email',
        category: 'First signatory',
        type: 'checkbox',
      },
    ],
    [
      {
        name: 'signature_firstname_2',
        displayName: 'Firstname',
        category: 'Second signatory',
        type: 'checkbox',
      },
      {
        name: 'signature_lastname_2',
        displayName: 'Lastname',
        category: 'Second signatory',
        type: 'checkbox',
      },
      {
        name: 'signature_phone_2',
        displayName: 'Tél.',
        category: 'Second signatory',
        type: 'checkbox',
      },
      {
        name: 'signature_email_2',
        displayName: 'Email',
        category: 'Second signatory',
        type: 'checkbox',
      },
    ],
  ],
};

export const SurveyJsOptions = {
  showEmbededSurveyTab: false,
  showDesignerTab: true,
  showTestSurveyTab: true,
  showToolbox: true,
  showPropertyGrid: true,
  questionTypes: ['text', 'checkbox', 'radiogroup', 'dropdown', 'file'],
};
