import React from 'react';
import { Route } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import ComponentWrapper from './ComponentWrapper/ComponentWrapper';

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: (state) => !!state.user.data,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
});

const UserIsAuthenticatedComponentWrapper = userIsAuthenticated(ComponentWrapper);

const StepTokenGuardedRoute = ({ component: Component, ...rest }) => {
  // You must rename the component prop otherwise the render is ignored
  return (
    <Route
      {...rest}
      render={(props) => {
        return <UserIsAuthenticatedComponentWrapper {...props} component={Component} />;
      }}
    />
  );
};

export default StepTokenGuardedRoute;
