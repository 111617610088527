/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { SurveyModel } from 'survey-react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { signDocuments } from '_redux/actions/acts/signDocuments';
import { errorHandler } from 'helpers/errorHandler';
import { IActResponse } from '_redux/reducers/act/types';

export interface IProps {
  survey: SurveyModel;
  actToken: string;
  act?: IActResponse;
}
const SignDocument: React.FC<IProps> = ({ survey, actToken, act }) => {
  const iFrame = useRef<HTMLIFrameElement>(null);
  const iFrameContainer = useRef<HTMLDivElement>(null);

  const [isSigned, setSigned] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const signHandler = () => {
      if (isSigned || act?.actSignature) {
        errorHandler(new Error('Signature déjà effectuée!'));

        return;
      }
      dispatch(signDocuments(survey, actToken, iFrameContainer, iFrame, setSigned, isSigned));
    };

    window.removeEventListener('survey.sign', signHandler);
    window.addEventListener('survey.sign', signHandler);

    return () => {
      window.removeEventListener('survey.sign', signHandler);
    };
  }, [survey, actToken, iFrameContainer, iFrame, isSigned, act]);

  useEffect(() => {
    const signWebCompleteHandler = (e: {
      origin: string;
      data: { type: string; status: string };
    }) => {
      if (
        e.origin !== `${process.env.REACT_APP_API_BASE_URL}` ||
        e.data.type !== 'survey.sign.web.complete'
      ) {
        return;
      }

      if (e.data.status !== 'success') {
        toast.error("La signature n'a pas été acceptée", {});
      } else {
        toast.success('La signature été acceptée', {});
      }
      if (iFrameContainer.current) iFrameContainer.current.style.display = 'none';
      window.removeEventListener('message', signWebCompleteHandler);
      window.addEventListener('message', signWebCompleteHandler);
    };

    return () => {
      window.removeEventListener('message', signWebCompleteHandler);
    };
  }, [iFrameContainer]);

  return (
    <>
      <div ref={iFrameContainer} id="kiora-form-sign-iframe-container">
        <iframe title="Sign Document" ref={iFrame} />
      </div>
    </>
  );
};

export default SignDocument;
